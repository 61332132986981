.profile-dropdown {
  list-style: none;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: -10px;
  border-radius: 4px;
  padding-right: 10px;
  border-style: dotted;
  min-width: fit-content;
  z-index: 2;
}

.profile-menu button {
  margin-top: 0px;
  padding: 0px 8px;
  border-radius: 3px;
  background-color: rgb(226, 73, 43);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.profile-menu button:hover {
  background-color: rgb(27, 247, 115);
}

.hidden {
  display: none;
}

.link.active {
  color: rgb(226, 73, 43);
}

.link {
  color: #E6E6FA;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s ease;
  font-family: sans-serif;
  font-size: 24px;
}

.nav-div {
  display: flex;
  justify-content: space-around;
  background-color: #2F4F4F;
  position: sticky;
  top: 0;
  z-index: 999;
}

.link:hover {
  color: rgb(27, 247, 115) !important;
  transform: scale(1.2) !important;
}

.brand-link:hover {
  color: rgb(27, 247, 115) !important;
}

.active-brand-link {
  color: rgb(226, 73, 43) !important;
}
