.exercise-diary-search-container .search-bar{
    width: 250px;
    height: 50px;
    padding: 0px 10px;
    overflow:auto;
    cursor: text;
    box-shadow: 0 0 10px rgba(142, 142, 142, 0.5);
    font-size: 16px;
    font-weight: 200;
    border: none;
    margin-bottom: 10px;
    margin-top: 10px;
}

.exercise-diary-search-container .search-entry{
    padding-left: 10px;
    padding-right: 10px;
}

.exercise-diary-search-container .search-entry:hover{
    background-color: rgb(226, 73, 43);
    border-radius: 3px;
}

.exercise-diary-search-container .search-entry-button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-diary-search-container .search-entry-button:hover {
    background-color: rgb(27, 247, 115);
}
