footer {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #212529;
  padding: 10px 0;
}

footer .footer-link {
  margin: 0 10px;
}

footer .footer-link i {
  font-size: 30px;
}

.footer-link.active {
  color: rgb(226, 73, 43);
}

.footer-link {
  color: #E6E6FA;
  height: 30px;
  transition: transform 0.3s ease;
}

.footer-link:hover {
  color: rgb(27, 247, 115) !important;
  transform: scale(1.2) !important;
}
