.hero-section {
  display: flex;
  background-image: url('background.png');
  background-size: cover;
  height: auto;
  width: auto;
  padding-top: 10vh;
  padding-bottom: 0vh;
  margin-left: -5px;
  background-attachment: absolute;
  z-index: -999;
}

.try-button {
  transform: translateX(-100%);
  position: absolute;
  width: 30%;
  height: 7%;
  min-height: 100px;
  left: 0px;
  animation: slide-in 0.5s 3s forwards;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  opacity: 0.8;
  overflow: hidden;
  z-index: 1;
}
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.start-tracking-image {
  position: absolute;
  width: 35vw;
  height: 100vh;
  object-fit: contain;
  opacity: 0;
  transition: opacity 1s;
  z-index: 0;
  animation-name: pop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes pop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.background {
  background-image: url('./Black and White Sports Fitness Animated Presentation.gif');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 80vh;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px black;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.right {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 100px;
}
