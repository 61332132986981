.food-diary-background {
  background-image: url('../../images/food_diary_image.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 140px;
}

.food-diary-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.calendar {
  position: absolute;
  opacity: .9;
  margin-top: -680px;
}

.food-entry:hover {
  background-color: rgb(203, 202, 211);
}

.food-entry {
  color: rgb(0, 0, 0);
  width: auto
}

.food-entry-amount {
  width: 50px;
  color: black;
  border-color: rgb(5, 5, 5);
  border-style: solid;
  border: 50px;
}

.food-entry-amount:hover {
  background-color: rgb(203, 202, 211);
  width: 50px;
}

.food-diary-list {
  background-color: rgb(255, 255, 255);
  width: 80vw;
  max-width: 700px;
  height: 400px;
  border-color: rgb(5, 5, 5);
  border-style: solid;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.food-diary-list::-webkit-scrollbar {
  display: none;
}


.food-diary-background .food-diary-list button {
  margin-top: 0px;
  padding: 0px 8px;
  border-radius: 3px;
  border: none;
  background-color: rgb(226, 73, 43);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.food-diary-background .food-diary-list button:hover {
  background-color: rgb(27, 247, 115);
}

.food-diary-background .calendar-button {
  margin-top: 0px;
  padding: 0px 8px;
  border-radius: 3px;
  background-color: rgb(226, 73, 43);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.food-diary-background .calendar-button:hover {
  background-color: rgb(27, 247, 115);
}

.food-diary-background .clear-diary {
  margin-top: 10px;
  padding: 0px 8px;
  border-radius: 3px;
  background-color: rgb(226, 73, 43);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.food-diary-background .clear-diary:hover {
  background-color: rgb(27, 247, 115);
}

.food-diary-background .add-food button{
  margin-top: 0px;
  padding: 0px 8px;
  border-radius: 3px;
  background-color: rgb(226, 73, 43);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.food-diary-background .add-food:hover {
  background-color: rgb(27, 247, 115);
}
