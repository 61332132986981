.search-bar {
    width: 250px;
    height: 50px;
    padding: 0px 10px;
    overflow: auto;
    cursor: text;
    box-shadow: 0 0 10px rgba(142, 142, 142, 0.5);
    font-size: 16px;
    font-weight: 200;
    border: none;
    border-radius: 3px;
}

.food-search-bar-container {
    background-image: url('../../images/food_search_image.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: auto;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 140px;
}

.search-bar-button {
    box-sizing: border-box;
    text-align: center;
    background-color: rgb(226, 73, 43);
    color: rgb(255, 255, 255);
    border-radius: 10%;
    width: 55px;
    height: 51px;
    border: none;
    cursor: pointer;
}

.search-bar-button:hover {
    background-color: rgb(226, 73, 43);
}

.entry:hover {
    background-color: rgb(226, 73, 43);
    border-radius: 3px;
}

.food-search-bar-container .common-list {
    width: 270px;
}

.food-search-bar-container .branded-list {
    width: 270px;
    padding-left: 15px;
}

.food-search-bar-container .rda {
    width: 370px;
}

.food-search-container {
    margin-top: 100px;
    height: auto;
    padding-bottom: 30px;
    min-height: 80vh;
    background-color: rgb(255, 255, 255, 0.7);
    border-radius: 10px;
}
