#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
  max-height: 700px;
  max-width: 800px;
  overflow-y: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#modal-content::-webkit-scrollbar {
  display: none;
}
