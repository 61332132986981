.food-diary-search-container .food-search-bar {
    width: 250px;
    height: 50px;
    padding: 0px 10px;
    overflow: auto;
    cursor: text;
    box-shadow: 0 0 10px rgba(114, 114, 114, 0.5);
    font-size: 16px;
    font-weight: 200;
    border: none;
    margin-top: 10px;
}

.food-diary-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}


.food-diary-search-container .search-bar-button {
    margin-top: 10px;
    box-sizing: border-box;
    text-align: center;
    background-color: rgb(226, 73, 43);
    border-radius: 10%;
    width: 60px;
    height: 51px;
    border: none;
    cursor: pointer;
}

.food-diary-search-container .search-entry {
    border-radius: 3px;
}
.food-diary-search-container .search-entry:hover {
    background-color: rgb(226, 73, 43);
    border-radius: 3px;
}

.food-diary-search-container .common-list {
    width: 270px;
    padding: 10px;
}

.food-diary-search-container .branded-list{
    width: 270px;
    padding: 10px;
}
