.profile-container-background {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url('../images/medical.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding-top: 100px;
}

.profile-container {
    padding-bottom: 100px;
}

.profile-container div {
    margin: 18px;
}

.profile-container input[type=number],
input[type=date],
select {
    width: 100%;
    padding: 5px 5px;
    margin: 0px 0;
    box-sizing: border-box;
    border-radius: 3px;
}

select {
    border-width: 2px;
}

.profile-container input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

.profile-container button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.profile-container button:hover {
    background-color: rgb(27, 247, 115);
}
