.exercise-diary-background {
    background-image: url('../../images/exercise_diary_image.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 140px;
}

.exercise-diary-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.exercise-diary-background .calendar {
    position: absolute;
    opacity: .9;
    margin-top: -243px;
}

.exercise-entry:hover {
    background-color: rgb(203, 202, 211);
}

.exercise-entry {
    color: rgb(0, 0, 0);
    width: auto
}

.exercise-entry-amount {
    width: 50px;
    color: black;
    border-color: rgb(5, 5, 5);
    border-style: solid;
    border: 50px;
}

.exercise-entry-amount:hover {
    width: 50px;
}

.exercise-diary-list {
    background-color: rgb(255, 255, 255);
    width: 80vw;
    max-width: 700px;
    height: 400px;
    border-color: rgb(5, 5, 5);
    border-style: solid;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.exercise-diary-list::-webkit-scrollbar {
    display: none;
}

::-webkit-input-placeholder {
    color: black;
}

.exercise-diary-background .clear-diary {
    margin-top: 10px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-diary-background .clear-diary:hover {
    background-color: rgb(27, 247, 115);
}

.exercise-diary-background .add-exercise button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-diary-background .add-exercise button:hover {
    background-color: rgb(27, 247, 115);
}

.exercise-diary-background .exercise-diary-list button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    border: none;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-diary-background .exercise-diary-list button:hover {
    background-color: rgb(27, 247, 115);
}

.exercise-diary-background .calendar-button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-diary-background .calendar-button:hover {
    background-color: rgb(27, 247, 115);
}

.exercise-entry-button {
    margin-top: 0px;
    padding: 0px 8px;
    border-radius: 3px;
    background-color: rgb(226, 73, 43);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.exercise-entry-button:hover {
    background-color: rgb(27, 247, 115);
}

.form-label {
    display: block;
    margin-bottom: 10px;
}

.form-label input {
    display: block;
    width: 100%;
    margin-top: 5px;
}

.exercise-entry-button {
    display: block;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
}
